/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import { empty } from '@/utils/variables'

export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object))
}

export function shallowCopy(object) {
  return {...object}
}

export function pick(object, array) {
  let result = {}
  for (let key of array) {
    if (typeof object[key] !== 'undefined') {
      result[key] = object[key]
    }
  }
  return result
}

/**
 * Call it with and empty formData and your object
 *
 * @param formData
 * @param data
 * @param _key
 */
export function objectToFormData(formData, data, _key) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      objectToFormData(formData, data[key], _key ? `${_key}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data

    formData.append(_key, value)
  }
}

export function getLastIndex(obj) {
  return Object.keys(obj)[Object.keys(obj).length - 1]
}

export function objectFromArray(array, key) {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    }
  }, initialValue)
}

export function clean(obj, replacement) {
  for (let key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue
    }

    if (empty(obj[key])) {
      if (typeof replacement !== 'undefined') {
        obj[key] = replacement
      } else {
        delete obj[key]
      }
    } else if (typeof obj[key] === 'object') {
      clean(obj[key], replacement)
      if (!Object.keys(obj[key]).length) {
        if (typeof replacement !== 'undefined') {
          obj[key] = replacement
        } else {
          delete obj[key]
        }
      }
    }
  }
  return obj
}

export function combine(keys, values) {
  let entries = []
  for (let i in keys) {
    if (!Object.prototype.hasOwnProperty.call(keys, i)) {
      continue
    }
    entries.push([keys[i], values[i]])
  }
  return Object.fromEntries(entries)
}

/**
 * Apply a map method to an object
 * The callable will receive as argument the key and the value and it must return the new key and new value
 */
export function mapObject(object, callable) {
  let result = {}
  Object.keys(object).map(function (key) {
    let child = callable(key, object[key])
    result[child[0]] = child[1]
  })
  return result
}

/**
 * Flatten an object passed as the first parameter using the callable as the second parameter.
 * The callable will receive as argument the key, the value,
 * a "push" callable called to push the current value into the results (no argument),
 * a "flatten" callable to recursively flatten the object which is passed to it as an argument.
 */
export function flatten(object, callable) {
  let flattened = {}
  let cb = object => Object.entries(object).forEach(([key, value]) => {
    let push = () => {
      flattened[key] = value
    }
    let flattenChildren = (children) => {
      flattened = {...flattened, ...flatten(children, callable)}
    }
    callable(key, value, push, flattenChildren)
  })
  cb(object)
  return flattened
}
