/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

export default {
  methods: {
    /**
     * Simply formatting date, as text entry
     * @param address
     * @returns {string}
     */
    addressDisplayer: function (address) {
      let parts = []
      if (address) {
        parts.push(address.street)
        parts.push([address.zipCode, address.city].filter(Boolean).join(' '))
        if (address.country && address.country.toUpperCase() !== 'FR') {
          parts.push(address.country)
        }
      }
      return parts.filter(Boolean).join(', ')
    },
    /**
     * Heat displayer based on completion rate %
     * @param rate
     * @returns {string}
     */
    completionToColor(rate) {
      let color = 'error'
      if (rate >= 80) {
        color = 'success'
      } else if (rate < 80 && rate >= 40) {
        color = 'warning'
      }
      return color
    },
    /**
     * Takes a user name and get initials from it
     * @param string
     * @returns {string}
     */
    nameToInitials(string) {
      let names = string.split(' ')
      if(names[0].includes('.')) {
        names.shift()
      }
      let initials = names[0].substring(0, 1).toUpperCase()

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    },

    /** Mise en forme du type d'admission **/
    labelStayType(type) {
      switch (type) {
        case 'ambulatory':
          return 'ambulatoire'
        case 'hospitalisation':
          return 'hospitalisation'
      }
    }
  }
}
