<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog :value="true" max-width="550" transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-icon color="white" small class="close-submit-dialog" @click="close">$close</v-icon>
      <v-list class="pa-0">
        <v-img class="p-abs img-header" src="@/assets/img/login-default-bg.png" min-width="250" width="100%"
               max-height="70px"
        />
        <div class="title-header">
          <v-list-item-title class="text-h5 font-weight-light white--text px-5 p-rel white--text pt-2">
            Utilisateur
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption font-alt mb-1 px-5 white--text p-rel">
            {{ user ? 'Modifier l\'utilisateur' : 'Créer un utilisateur' }}
          </v-list-item-subtitle>
        </div>
        <div class="splitter" />
        <v-form ref="user" lazy-validation class="mt-2" @submit.prevent="submit">
          <v-row class="ma-0">
            <v-col cols="12">
              <text-field v-model="model.email" label="Email de l'utilisateur" rounded :disabled="editForm"
                          :rules="[$rules.required, $rules.email, $rules.maxLength(200)]"
              />
            </v-col>
            <v-col cols="12">
              <v-select v-model="model.permissions" label="Permissions" multiple
                        color="secondary" outlined dense rounded hide-details="auto"
                        :items="permissions" :disabled="permissions.length < 2"
              />
            </v-col>
            <v-col cols="12" md="6">
              <text-field v-model="model.lastName" label="Nom de l'utilisateur" rounded min-length="2"
                          :rules="[$rules.required, $rules.maxLength(200)]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <text-field v-model="model.firstName" label="Prénom de l'utilisateur" rounded min-length="2"
                          :rules="[$rules.required, $rules.maxLength(200)]"
              />
            </v-col>
          </v-row>
          <v-card-actions class="px-3">
            <v-spacer />
            <v-btn x-small rounded text @click="close">
              Annuler
            </v-btn>
            <v-btn x-small rounded color="success" type="submit" :loading="submitting" :disabled="submitting">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import { create, identityEstablishments } from '@/modules/users/api/professionals'
  import api from '@/services/api'
  import { deepCopy } from '@/utils/object'
  import { compare } from '@/utils/array'
  import logger from '@/services/logger'
  import TextField from '@/modules/core/components/TextField'

  export default {
    name: 'UserForm',
    components: {TextField},
    props: {
      user: Object
    },
    data() {
      let professionalIdentity = null

      if (this.user && this.user.identity.professionalIdentityEstablishments.length) {
        let activeEstablishmentIri = this.$store.getters['establishments/activeEstablishment']['@id']
        professionalIdentity = this.user.identity.professionalIdentityEstablishments.find(membership => {
          return typeof membership.establishment === 'string' ? membership.establishment === activeEstablishmentIri : membership.establishment['@id'] === activeEstablishmentIri
        })
      }

      return {
        editForm: false,
        submitting: false,
        localUser: deepCopy(this.user),
        permissions: [{
          text: 'Gérer les admissions',
          value: 'PERMISSION_ADMISSION'
        }, {
          text: 'Gérer les questionnaires',
          value: 'PERMISSION_SURVEY'
        }],
        model: {
          permissions: professionalIdentity ? professionalIdentity.permissions : ['PERMISSION_ADMISSION'],
          email: this.user ? this.user.username : '',
          firstName: this.user ? this.user.identity.firstName : '',
          lastName: this.user ? this.user.identity.lastName : ''
        }
      }
    },
    created() {
      this.checkEdit()
    },
    methods: {
      checkEdit() {
        if (this.user) {
          this.editForm = true
        }
      },
      async submit() {
        if (!this.$refs.user.validate()) {
          return false
        }
        try {
          this.submitting = true

          if (this.user) {
            if (this.user.firstName !== this.model.firstName || this.user.lastName !== this.model.lastName) {
              await api.put(this.user.identity['@id'], {
                firstName: this.model.firstName,
                lastName: this.model.lastName,
                birthName: this.model.lastName
              })
              this.localUser.identity.firstName = this.model.firstName
              this.localUser.identity.lastName = this.model.lastName
              this.localUser.identity.birthName = this.model.lastName
            }

            let activeEstablishmentIri = this.$store.getters['establishments/activeEstablishment']['@id']
            let membership = null

            if (this.localUser) {
              membership = this.localUser.identity.professionalIdentityEstablishments.find(membership => {
                return typeof membership.establishment === 'string' ? membership.establishment === activeEstablishmentIri : membership.establishment['@id'] === activeEstablishmentIri
              })
            }

            if (membership && !compare(membership.permissions, this.model.permissions)) {
              await api.put(membership['@id'], {
                permissions: this.model.permissions
              })
              this.$set(membership, 'permissions', this.model.permissions)
            }
          } else {
            this.localUser = (await create({
              email: this.model.email,
              firstName: this.model.firstName,
              lastName: this.model.lastName,
              birthName: this.model.lastName
            })).data

            this.localUser.identity.professionalIdentityEstablishments.push((await identityEstablishments.create({
              professionalIdentity: this.localUser.identity['@id'],
              establishment: this.$store.getters['establishments/activeEstablishment']['@id'],
              permissions: this.model.permissions
            })).data)
          }

          this.$emit('update:user', this.localUser)

          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: this.user ? 'Utilisateur modifié avec succès.' : 'Utilisateur crée avec succès.'
          })
          this.$emit('close')
        } catch (error) {
          logger.error(error)
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.submitting = false
        }
      },
      close() {
        this.$emit('close')
      }
    }
  }
</script>

