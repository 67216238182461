<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-x">
    <v-card-title class=" font-alt pb-3">Utilisateurs</v-card-title>
    <v-card-subtitle class="o-60">Liste des utilisateurs</v-card-subtitle>
    <skeleton-list v-if="loading" />
    <v-list v-else class="pa-0">
      <v-list-item two-line class="click-to-create" :class="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                   @click.stop="userForm = true"
      >
        <v-list-item-avatar>
          <v-avatar size="40" color="success lighten-1">
            <font-awesome-icon :icon="['fas', 'plus']" size="1x" class="white--text" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-alt font-weight-medium">Ajouter un utilisateur</v-list-item-title>
          <v-list-item-subtitle class="op-60 font-weight-light">Créer un nouvel utilisateur</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <empty-list v-if="users.length === 0" title="Aucun utilisateur"
                  subtitle="L'établissement ne possède aucun utilisateur."
      />
      <template v-for="(user, index) in users">
        <v-list-item :key="user.id" two-line @click.stop="select(user)">
          <v-list-item-avatar>
            <v-avatar color="grey lighten-2" size="40" class="white--text">
              {{ nameToInitials(user.identity.firstName + ' ' +user.identity.lastName) }}
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-alt font-weight-normal">
              {{ user.identity.firstName }}
              {{ user.identity.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle class="op-60 font-weight-light">{{ user.username }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index < users.length -1" :key="index" />
      </template>
      <div v-if="totalItems > itemsPerPage" class="py-3 d-flex justify-center">
        <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="3"
          color="grey"
          @input="loadUsers"
        />
      </div>
    </v-list>
    <user-form v-if="userForm" :user="item" @update:user="updateUser"
               @close="closeDialog"
    />
  </v-card>
</template>

<script>
  import EmptyList from '@/modules/core/components/EmptyList'
  import UserForm from '@/modules/users/components/UserForm'
  import SkeletonList from '@/modules/core/components/SkeletonList'
  import * as professionals from '@/modules/users/api/professionals'
  import {removeItem, replaceItem} from '@/utils/array'
  import dataDisplayers from '@/mixins/dataDisplayers'

  export default {
    name: 'UsersList',
    components: {SkeletonList, UserForm, EmptyList},
    mixins: [dataDisplayers],
    data() {
      return {
        userForm: false,
        loading: false,
        users: null,
        item: null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        totalItems: 0
      }
    },
    watch: {
      '$store.state.establishments.activeEstablishmentId': {
        handler() {
          this.page = 1
          this.loadUsers()
        },
        immediate: true
      }
    },
    methods: {
      closeDialog() {
        this.userForm = false
        this.item = null
        this.remove()
      },
      async loadUsers() {
        this.loading = true
        try {
          await professionals.list({
            params: {
              page: this.page,
              itemsPerPage: this.itemsPerPage,
              establishment: this.$store.state.establishments.activeEstablishmentId
            }
          }).then((response) => {
            this.users = response.data['hydra:member']
            this.totalItems = response.data['hydra:totalItems']
            this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.loading = false
        }
      },
      remove(user) {
        removeItem(this.users, user)
      },
      select(user) {
        this.item = user
        this.userForm = true
      },
      updateUser(data) {
        if(this.item) {
          replaceItem(this.users, this.item, data)
        } else {
          this.users.push(data)
        }
      }
    }
  }
</script>

