<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-text-field ref="field" v-model="localValue" type="text" color="secondary" outlined dense hide-details="auto"
                :counter="counter" :counter-value="counterValue"
                :rules="allRules" v-bind="$attrs" @update:error="onError" v-on="$listeners"
  >
    <template v-if="errorIcon" v-slot:append>
      <v-icon small :color="isError ? 'red lighten-3' : 'success'" class="pt-2">$warning</v-icon>
    </template>
  </v-text-field>
</template>

<script>
  import { empty } from '@/utils/variables'

  export default {
    name: 'TextField',
    props: {
      rules: Array,
      errorIcon: {
        type: Boolean,
        // eslint-disable-next-line vue/no-boolean-default
        default: function () {
          return !empty(this.rules)
        }
      },
      error: Boolean,
      enableCounter: Boolean,
      value: [String, Number],
      minLength: [String, Number],
      maxLength: [String, Number]
    },
    data() {
      return {
        localError: false,
        localValue: this.value
      }
    },
    computed: {
      isError() {
        return this.error || this.localError
      },
      counter() {
        return this.enableCounter && this.maxLength && this.localValue.length > parseInt(this.maxLength)
      },
      counterValue() {
        return () => this.localValue.length + (this.maxLength ? '/' + this.maxLength : '')
      },
      allRules() {
        if(!Array.isArray(this.rules)) {
          return []
        }
        let rules = [...this.rules]
        if (this.minLength) {
          rules.push(this.$rules.minLength(this.minLength))
        }
        if (this.maxLength) {
          rules.push(this.$rules.maxLength(this.maxLength))
        }
        return rules
      }
    },
    watch: {
      value(value) {
        this.localValue = value
      },
      localValue(value) {
        this.$emit('input', value)
      }
    },
    mounted() {
      this.localError = this.$refs.field.hasError
    },
    methods: {
      onError(value) {
        this.localError = value
      }
    }
  }
</script>

<style scoped>

</style>
